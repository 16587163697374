<template>
  <v-container grid-list-xl>
    <v-overlay :value="pageLoadingOverlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <!-- CUSTOMER INFO -->
    <h4 class="text-center mt-4">RESERVE YOUR NEXT WORKOUT</h4>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <v-col cols="12" xs="12" sm="12">
        <v-sheet class="mx-auto">
          <v-slide-group @change="changeDates">
            <v-slide-item
              v-for="(day, ix) in daysArray"
              :key="ix"
              v-slot="{ active, toggle }"
            >
              <div class="text-center">
                <span class="caption">{{ day.dayOfWeek }}</span
                ><br />
                <v-btn
                  class="mx-2"
                  :input-value="active"
                  active-class="primary white--text"
                  depressed
                  rounded
                  small
                  style="min-width: 40px !important"
                  @click="handleClick(toggle, day)"
                >
                  {{ day.day }}
                </v-btn>
              </div>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-row class="mb-0" v-if="daySelected">
        <v-col
          cols="12"
          xs="12"
          sm="12"
          v-for="(slot, x) in daySelected.slots"
          :key="x"
        >
          <h3 class="font-weight-light ml-2 pb-2" v-if="x === 0">
            {{ daySelected.formattedDay }}
          </h3>
          <v-card
            outlined
            :color="slot.reserved ? '#f1faf1' : '#fafafa'"
            style="border-color: #e2e2e2"
          >
            <v-card-title primary-title>{{ slot.timeFrame }} </v-card-title>
            <v-card-text>
              <div class="green--text" v-if="slot.reserved">RESERVED</div>
              <div>
                {{ slot.description }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                outlined
                :disabled="slot.reserved"
                @click="reserve(daySelected, slot)"
              >
                <v-icon class="mr-1" small>check_circle_outline</v-icon>
                RESERVE</v-btn
              >
              <v-btn
                color="primary"
                text
                outlined
                v-if="slot.reserved"
                @click="cancelReservation({ ...slot, ...daySelected }, true)"
                ><v-icon small class="mr-1">highlight_off</v-icon> CANCEL
                RESERVATION</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-expand-transition>

    <v-row class="mb-2 mt-8">
      <v-col cols="12" xs="12" sm="12" class="mb-0 pb-0">
        <v-row align="center">
          <v-col>
            <h3 class="ml-2">WORKOUT SUMMARY</h3>
          </v-col>
          <v-col class="text-right" cols="auto">
            <v-btn text color="primary">VIEW ALL</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="6"
        xs="6"
        sm="6"
        v-for="summary in summaryWorkouts"
        :key="summary.timeFrame"
      >
        <v-card height="150" color="secondary" dark class="px-4">
          <v-card-text class="pt-4 px-1 text-left">
            <h2 class="font-weight-light pt-5 mb-4">{{ summary.timeFrame }}</h2>
            <h1 class="display-1">{{ summary.numberOfWorkouts }}</h1>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mb-2 mt-8">
      <v-col cols="12" xs="12" sm="12" class="mb-0 pb-0">
        <h3 class="ml-2">UPCOMING WORKOUTS</h3>
      </v-col>
      <v-col cols="12" xs="12" sm="12">
        <div v-if="!reservations.length">
          <v-card outlined color="#f1faf1" style="border-color: #bdd4b7">
            <v-card-text>
              Uh-oh! It looks like your gloves need some love. Book your session
              above and lets work on that 1,2 combo! 🥊
            </v-card-text>
          </v-card>
        </div>
        <div>
          <v-card
            outlined
            color="#f1faf1"
            style="border-color: #bdd4b7"
            v-for="(reservation, ix) of reservations"
            :key="ix"
            class="mb-4"
          >
            <v-card-title
              class="font-weight-regular"
              style="flex-direction: column; align-items: start"
              >{{ reservation.formattedDay }}
              <span class="caption mt-1"> 6:30 AM - 10:30 AM</span>
            </v-card-title>
            <v-card-text class="">
              <div class="">
                {{ reservation.description }}
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                outlined
                @click="cancelReservation(reservation)"
                ><v-icon small class="mr-1">highlight_off</v-icon> CANCEL
                RESERVATION</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import fb from "@/main";
export default {
  name: "Home",
  data() {
    return {
      pageLoadingOverlay: true,
      search: "",
      daySelected: null,
      loading: true,
      dataExists: false,
      snack: false,
      snackColor: "",
      snackText: "",
      estDate: null,
      daysArray: [],
      reservations: [],
      today: moment().format("YYYY-MM-DD"),
      summaryWorkouts: [
        {
          timeFrame: "Week",
          numberOfWorkouts: 0,
        },
        {
          timeFrame: "Month",
          numberOfWorkouts: 0,
        },
        {
          timeFrame: "Year",
          numberOfWorkouts: 0,
        },
        {
          timeFrame: "All Time",
          numberOfWorkouts: 0,
        },
      ],
    };
  },
  async created() {
    this.getAll();
    this.getSummaryWorkouts();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    handleClick(toggle, day) {
      this.daySelected = day;
      return toggle();
    },
    changeDates(selectedItemIndex) {
      if (selectedItemIndex === undefined) {
        this.daySelected = null;
      }
    },
    async reserve(daySelected, slot) {
      this.pageLoadingOverlay = true;
      try {
        console.log("daySelected", daySelected);
        console.log("slot", slot);
        // save reservation to firestore collection reservations asynchronously
        daySelected = { ...daySelected };
        delete daySelected.slots;
        const reservation = {
          ...daySelected,
          ...slot,
          userId: this.user.uid,
          createdAt: fb.timestamp,
        };

        const reservationSnap = await fb.db
          .collection("reservations")
          .add(reservation);
        // add id to reservation object
        slot.id = reservationSnap.id;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully reserved workout.";
        slot.reserved = true;
        await this.getAll();
        this.pageLoadingOverlay = false;
      } catch (error) {
        console.log("error", error);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error reserving workout.";
        this.pageLoadingOverlay = false;
      }
    },
    async cancelReservation(reservation, cancelBySlot = false) {
      console.log("reservation", reservation);
      this.pageLoadingOverlay = true;
      try {
        if (cancelBySlot) {
          // Find the reservation document by its id
          const snapshot = await fb.db
            .collection("reservations")
            .where("date", "==", reservation.date)
            .where("session", "==", reservation.session)
            .where("userId", "==", this.user.uid) // Ensure you're deleting the right user's reservation
            .get();

          const deletePromises = snapshot.docs.map((doc) => doc.ref.delete());
          await Promise.all(deletePromises);
        } else {
          await fb.db.collection("reservations").doc(reservation.id).delete();
        }

        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully cancelled reservation.";
        reservation.reserved = false;
        const index = reservation.session === "morning" ? 0 : 1;
        if (this.daySelected && this.daySelected.slots) {
          Vue.set(this.daySelected.slots, index, {
            ...reservation,
            reserved: false,
          });
        }
        this.getAll();
        this.pageLoadingOverlay = false;
      } catch (error) {
        console.log("error", error);
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error cancelling reservation.";
        this.getAll();
        this.pageLoadingOverlay = false;
      }
    },
    async getReservations() {
      try {
        const snapshot = await fb.db
          .collection("reservations")
          .where("userId", "==", this.user.uid)
          .where("date", ">=", this.today)
          .get();
        const reservations = snapshot.docs.map((doc) => {
          console.log("doc", doc.id);
          return { id: doc.id, ...doc.data() };
        });
        this.reservations = reservations;
        return reservations;
      } catch (error) {
        console.error("Error fetching reservations: ", error);
        return [];
      }
    },
    async getAll() {
      this.daysArray = [];
      this.pageLoadingOverlay = true;
      try {
        const currentDay = moment();
        this.daysArray = [];

        // Create an array of 14 days
        for (let i = 0; i < 14; i++) {
          const day = currentDay.clone().add(i, "days");
          const data = {
            day: day.date(),
            dayOfWeek: day.format("ddd"),
            dayOfWeekFull: day.format("dddd"),
            date: day.format("YYYY-MM-DD"),
            formattedDay: day.format("dddd, MMMM D"),
          };
          if (["Mon", "Tue", "Wed", "Thu"].includes(day.format("ddd"))) {
            this.daysArray.push({
              slots: [
                {
                  timeFrame: "6:30 AM - 10:30 AM",
                  session: "morning",
                  description:
                    "Arrive anytime between 6:30 AM and about 9:45 AM (to get the full workout).",
                  reserved: false,
                },
                {
                  timeFrame: "4:00 PM - 7:30 PM",
                  session: "evening",
                  description:
                    "Arrive anytime between 4:00 PM and about 6:45 PM (to get the full workout).",
                  reserved: false,
                },
              ],
              ...data,
            });
          } else if (day.format("ddd") === "Fri") {
            this.daysArray.push({
              slots: [
                {
                  timeFrame: "6:30 AM - 10:30 AM",
                  session: "morning",
                  description:
                    "Arrive anytime between 6:30 AM and about 9:45 AM (to get the full workout).",
                  reserved: false,
                },
              ],
              ...data,
            });
          } else if (day.format("ddd") === "Sat") {
            this.daysArray.push({
              slots: [
                {
                  timeFrame: "7:00 AM - 11:00 AM",
                  session: "morning",
                  description:
                    "Arrive anytime between 7:00 AM and about 10:15 AM (to get the full workout).",
                  reserved: false,
                },
              ],
              ...data,
            });
          }
        }

        // Fetch reservations from today
        const reservations = await this.getReservations();

        // Mark slots as reserved if there is a match
        this.daysArray.forEach((day) => {
          day.slots.forEach((slot) => {
            const reserved = reservations.some(
              (reservation) =>
                reservation.date === day.date &&
                reservation.session === slot.session
            );
            if (reserved) {
              slot.reserved = true;
            }
          });
        });
      } catch (error) {
        console.error("Error loading reservations: ", error);
      } finally {
        this.pageLoadingOverlay = false;
      }
    },
    async getSummaryWorkouts() {
      // get summary workouts from aggregate collection called memberMetrics using doc id `${this.user.meberId}_${this.user.locationId}`
      try {
        console.log(this.user);
        const doc = await fb.db
          .collection("memberMetrics")
          .doc(this.user.memberId)
          .get();
        if (doc.exists) {
          const data = doc.data();
          console.log("data", data);
          this.summaryWorkouts = [
            {
              timeFrame: "Week",
              numberOfWorkouts: data.weekly.count,
            },
            {
              timeFrame: "Month",
              numberOfWorkouts: data.monthly.count,
            },
            {
              timeFrame: "Year",
              numberOfWorkouts: data.yearly.count,
            },
            {
              timeFrame: "All Time",
              numberOfWorkouts: data.allTime.count,
            },
          ];
        }
      } catch (error) {
        console.error("Error fetching summary workouts: ", error);
      }
    }
  },
};
</script>
