import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import fb from "@/main";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    user: null,
    exercises: [],
  },
  getters: {
    user: (state) => {
      return state.user;
    },
    exercises: (state) => {
      return state.exercises;
    },
  },
  mutations: {
    cacheUser(state, user) {
      state.user = user;
    },
    cacheExercises(state, exercises) {
      state.exercises = exercises;
    },
  },
  actions: {
    signout: async function () {
      try {
        await fb.auth.signOut();
        router.push("/login");
      } catch (error) {
        alert(error);
      }
    },
    getUser: async function ({ commit }) {
      try {
        const { uid } = await fb.auth.currentUser;
        const userRes = await fb.db.collection("users").doc(uid).get();
        let user = userRes.data();
        commit("cacheUser", user);
      } catch (error) {
        alert(error);
      }
    },
    getExercises: async function () {
      try {
        const exercisesRef = await fb.db.collection("exercises").get();
        let exercises = [];
        exercisesRef.forEach((exercise) => {
          exercises.push({
            dbId: exercise.id,
            ...exercise.data(
            )});
        });
        return exercises
        // commit("cacheExercises", exercises);
      } catch (error) {
        alert(error.message);
      }
    },
    saveExercise: async function (_,exercise) {
      try {
        console.log(exercise);
        await fb.db.collection("exercises").doc(exercise.dbId).update(exercise);
        return true
      } catch (error) {
        alert(error.message);
      }
    },
    createExercise: async function (_,exercise) {
      try {
        console.log(exercise);
        const storageRef = fb.storage.ref();
        const fileName = `exercises/${exercise.id}.mp4`;
        const imageRef = storageRef.child(fileName);
        const options = {
          contentType: "video/mp4",
        };
        await imageRef.put(exercise.file, options);
        exercise.videoUrl = await imageRef.getDownloadURL();
        exercise.createDate = fb.timestamp;
        delete exercise.file;
        console.log('before save',exercise);
        await fb.db.collection("exercises").add(exercise);
        return true
      } catch (error) {
        alert(error.message);
      }
    }
  },
});

export default store;
