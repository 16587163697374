import Vue from "vue";
import Router from "vue-router";
import fb from "@/main";
import store from "@/store";

import Home from "@/views/Home.vue";
import Login from "./views/Login.vue";
import NotFound from "./views/NotFound.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { requiresAuth: true },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "*",
      component: NotFound,
      meta: { requiresAuth: true }
    }
  ],
});
router.beforeEach(async (to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if NO logged userd
    if (!fb.auth.currentUser) {
      next({
        path: "/login",
      });
    } else if(store.getters['user']) {
        // don't need o fetch user, already cached
        next()
      } else {
        const { uid } = await fb.auth.currentUser;
        const doc = await fb.db.collection('users').doc(uid).get()
        store.commit("cacheUser", {...doc.data(),uid: doc.id})
        next();
      }

  } else {
    // Proceed to route
    next();
  }
});
export default router;
